'use client';

import { forwardRef, useCallback, useContext, ReactNode, useState, useMemo } from 'react';

import { AssistantQuery, OwnerType } from '@/@generated/graphql';
import {
  getUseChatsQueryKey,
  getUseContentByChatQueryKey,
  useChatCreateMutation,
  useContentUpsertByChatMutation,
} from '@/lib/swr/hooks';
import { Dropzone } from '@unique/component-library';
import {
  SUPPORTED_MIME_TYPES,
  SUPPORTED_MIME_TYPES_IMAGE,
  ScrollWrapperContext,
  ToastVariant,
  useFileUpload,
  useToast,
} from '@unique/shared-library';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ErrorCode, FileRejection, FileWithPath } from 'react-dropzone';
import { ConfigurationContext } from '@/providers/ConfigurationProvider';
interface ChatDropzoneProps {
  children: ReactNode;
  assistantQuery?: AssistantQuery;
  handleUploadFailed?: () => void;
  disableClick?: boolean;
}

const DEFAULT_BORDER_COLOR = 'border-control';

export const ChatDropzone = forwardRef<HTMLElement, ChatDropzoneProps>((props, ref) => {
  const { children, assistantQuery, handleUploadFailed, disableClick } = props;
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { scrollToBottom } = useContext(ScrollWrapperContext);
  const { maxFiles, maxFileSize } = useContext(ConfigurationContext);
  const [borderColor, setBorderColor] = useState(DEFAULT_BORDER_COLOR);

  const selectedAssistantId = assistantQuery?.assistantByUser?.id;

  const acceptableMimeTypes = useMemo(() => {
    const { settings, modules } = assistantQuery?.assistantByUser || {};
    const allowUploadingImages = !!settings?.imageUpload || false;

    const allowOnlyImages =
      modules?.every(
        module => module.name === 'ImageSummarizer' || module.name === 'ImageUpload',
      );

    if (allowOnlyImages) {
      return SUPPORTED_MIME_TYPES_IMAGE;
    }

    if (!allowUploadingImages) {
      return SUPPORTED_MIME_TYPES;
    }

    return { ...SUPPORTED_MIME_TYPES, ...SUPPORTED_MIME_TYPES_IMAGE };
  }, [assistantQuery]);

  const cacheKey = getUseContentByChatQueryKey({
    chatId: id,
  });

  const getIngestionConfig = useCallback(() => {
    const config = assistantQuery?.assistantByUser?.settings?.ingestionConfig;
    return config && Object.keys(config).length > 0 ? config : null;
  }, [assistantQuery]);

  const { trigger: upsertContent } = useContentUpsertByChatMutation(cacheKey);
  const { trigger: createChat } = useChatCreateMutation(getUseChatsQueryKey());

  const { handleUpload } = useFileUpload<OwnerType>({
    setError: (error) => showToast({ message: error, variant: ToastVariant.ERROR }),
    chatId: id,
    chatOwnerType: OwnerType.Chat,
    upsertContent,
    ownerType: OwnerType.Chat,
    upsertContentAttributeName: 'contentUpsertByChat',
    storeInternally: true,
    assistantIngestionConfig: getIngestionConfig(),
    maxFiles: maxFiles,
    maxFileSize: maxFileSize,
  });

  const handleChatUpload = async (
    acceptedFiles: FileWithPath[],
    fileRejections: FileRejection[],
  ) => {
    if (fileRejections.length) {
      const code = fileRejections[0].errors[0].code;
      let message = `Unknown Error (${code})`;
      switch (code) {
        case ErrorCode.FileTooLarge:
          message = `File too large. The current limit is ${maxFileSize/1024/1024} MB per file.`;
          break;
        case ErrorCode.FileInvalidType:
          message = 'Invalid File Type';
          break;
        case ErrorCode.FileTooSmall:
          message = 'File size is too small';
          break;
        case ErrorCode.TooManyFiles:
          message = `Too many files. You can upload a maximum of ${maxFiles} files at once.`;
          break;
      }
      showToast({ message, variant: ToastVariant.ERROR });
      scrollToBottom();
      handleUploadFailed?.();
      setBorderColor(DEFAULT_BORDER_COLOR);
      return;
    }
    if (id) {
      handleUpload(acceptedFiles, fileRejections);
    } else {
      try {
        // use first file name as chat title for the case where the upload is the first action in the new chat
        const chatTitle = acceptedFiles[0]?.name || '';
        const chatResponse = await createChat({
          title: chatTitle,
          assistantId: selectedAssistantId,
        });
        const newChatId = chatResponse?.chatCreate?.id;
        handleUpload(acceptedFiles, fileRejections, { newChatId });
        navigate(`/${newChatId}`);
      } catch (e) {
        console.log(e);
      }
      scrollToBottom();
    }
  };

  return (
    <Dropzone
      ref={ref}
      onDrop={handleChatUpload}
      onError={(e) => console.log(e)}
      accept={acceptableMimeTypes}
      maxFiles={maxFiles}
      maxSize={maxFileSize}
      additonalClassname="h-full pt-5 pb-2"
      disableClick={disableClick}
    >
      <div
        onDragOver={() => setBorderColor('border-primary-cta')}
        onDragLeave={() => setBorderColor(DEFAULT_BORDER_COLOR)}
        className={`${borderColor} h-full min-h-[170px] flex-1 overflow-y-auto rounded-lg border-2 border-dashed md:min-h-[250px]`}
      >
        {children}
      </div>
    </Dropzone>
  );
});

export default ChatDropzone;

ChatDropzone.displayName = 'ChatDropzone';
