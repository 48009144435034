import {
  getUseMagicTableCellKey,
  useMagicTableCellUpdateSubscription,
  useUpdateMagicTableCellMutation,
} from '@/lib/swr/hooks';
import { logger } from '@unique/next-commons/logger';
import { GraphQLError } from 'graphql';
import { useEffect, useMemo } from 'react';

const log = logger.child({
  package: 'chat',
  namespace: 'components:magic-table-component:useUpdateCell',
});

type Props = {
  sheetId: string;
  updateCellValues: (rowNode: number, columnNode: string, newValue: string) => void;
  getColumnAtIndex: (index: number) => string;
};

export default function useUpdateCell({ sheetId, updateCellValues, getColumnAtIndex }: Props) {
  const { trigger: updateCell } = useUpdateMagicTableCellMutation(getUseMagicTableCellKey());

  const subscriptionVariables = useMemo(() => ({ sheetId }), [sheetId]);

  const clientWs = useMagicTableCellUpdateSubscription(
    {
      next: (data) => {
        const update = data.magicTableCellUpdate;
        const rowOrder = update.magicTableRow.rowOrder;
        const columnOrder = getColumnAtIndex(update.magicTableColumn.columnOrder);
        updateCellValues(rowOrder, columnOrder, update.data);
      },
      error: (errors: GraphQLError[]) => {
        log.error(`Magic Table fetch subscription error. Error: ${JSON.stringify(errors)}`);
      },
      complete: () => {
        log.info('Magic Table subscription complete');
      },
    },
    subscriptionVariables,
  );

  useEffect(() => {
    clientWs.on('connected', () => {
      log.info('Connected to socket, MagicTableCellUpdateSubscription');
    });

    clientWs.on('closed', () => {
      log.info('Disconnected from socket, MagicTableCellUpdateSubscription');
    });
    return () => {
      clientWs.terminate();
    };
  }, []);

  return { updateCell } as const;
}
