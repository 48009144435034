import { AssistantQuery } from '@/@generated/graphql';

export const getAcceptableFileTypes = (assistant: AssistantQuery['assistantByUser']) => {
  const { settings, modules } = assistant || {};
  const allowUploadingImages = !!settings?.imageUpload || false;

  const allowOnlyImages =
    modules?.every(
      module => module.name === 'ImageSummarizer' || module.name === 'ImageUpload',
    );

  if (allowOnlyImages) {
    return 'PNG, JPG';
  }

  if (!allowUploadingImages) {
    return 'PDF, DOCx, HTML, MD, TXT';
  }

  return 'PDF, DOCx, HTML, MD, TXT, PNG, JPG';
};