'use client';
import { ErrorPage } from '@unique/shared-library';

import { ChatAnalytics } from '@/components/Chat/ChatAnalytics';
import { ChatBenchmarking } from '@/components/Chat/ChatBenchmarking';
import { ChatFeedback } from '@/components/Chat/ChatFeedback';
import { ExploreSpacePage } from '@/components/Space/ExploreSpacePage';
import { SpacePage } from '@/components/Space/SpacePage';
import DueDiligencePage from '@/components/DueDiligence/DueDiligencePage';
import { ChatMessagesApp } from '@/components/Chat/ChatMessagesApp';
import { SpaceLandingPage } from '@/components/Space/SpaceLandingPage';
import Layout from '@/components/Layout/Layout';
import CreateDueDiligence from '@/components/Space/CreateDueDiligence';

// react router requires a unique id as string for each route
export enum ROUTES_IDS {
  HOMEPAGE = 'HOMEPAGE',
  CHAT = 'CHAT',
  ANALYTICS = 'ANALYTICS',
  BENCHMARKING = 'BENCHMARKING',
  FEEDBACK = 'FEEDBACK',
  SPACE = 'SPACE',
  SPACE_ID = 'SPACE_ID',
  CREATE_WORKSHEET = 'CREATE_WORKSHEET',
  SPACE_ID_SLUG = 'SPACE_ID_SLUG',
  SPACE_EXPLORE = 'SPACE_EXPLORE',
}

export const routes = [
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        id: ROUTES_IDS.HOMEPAGE,
        path: '',
        element: <SpaceLandingPage />,
      },
      {
        id: ROUTES_IDS.ANALYTICS,
        path: 'analytics',
        element: <ChatAnalytics />,
      },
      {
        id: ROUTES_IDS.BENCHMARKING,
        path: 'benchmarking',
        element: <ChatBenchmarking />,
      },
      {
        id: ROUTES_IDS.FEEDBACK,
        path: 'feedback',
        element: <ChatFeedback />,
      },
      {
        id: ROUTES_IDS.SPACE,
        path: 'space',
        children: [
          {
            id: ROUTES_IDS.CREATE_WORKSHEET,
            path: ':spaceId/create-worksheet',
            element: <CreateDueDiligence />,
          },
          {
            id: ROUTES_IDS.SPACE_ID_SLUG,
            path: ':spaceId/:spaceSlug',
            element: <DueDiligencePage />,
          },
          {
            id: ROUTES_IDS.SPACE_ID,
            path: ':spaceId',
            element: <SpacePage />,
          },
          {
            id: ROUTES_IDS.SPACE_EXPLORE,
            path: 'explore',
            element: <ExploreSpacePage />,
          },
        ],
      },
      {
        id: ROUTES_IDS.CHAT,
        path: ':id',
        element: <ChatMessagesApp />,
      },
    ],
  },
];
