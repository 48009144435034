import { useMagicTableQuery } from '@/lib/swr/hooks';
import { logger } from '@unique/next-commons/logger';

const log = logger.child({
  package: 'chat',
  namespace: 'components:magic-table-component:useMagicTableQuerySubscription',
});

type Props = { sheetId: string };

export default function useDueDiligenceQuery({ sheetId }: Props) {
  const {
    data,
    isLoading: loadingTableData,
    isValidating: validatingDueDiligence,
  } = useMagicTableQuery(
    { cursor: { id: sheetId } },
    {
      onError: () => {
        log.error('Unable to fetch Magic Table Data');
      },
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const magicTableData = data?.getTransformedMagicTableSheet?.magicTableSheet;

  return { magicTableData, loadingTableData, validatingDueDiligence } as const;
}
